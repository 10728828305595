






















































































































































































import { Component, Prop } from 'vue-property-decorator'

import { AccordionItem } from '../../../../dsl/atoms/Accordion'
import { CustomHeading } from '../../../../dsl/atoms/Heading'
import { CustomNav } from '../../../../dsl/molecules/Nav'

import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType, TabOption } from '../../../../contexts'

import { FooterProps } from './Footer.contracts'
import { StructureConfigurable } from '../../../../support/mixins'
import { FOOTER_COMPONENT_CONFIG_MAP, FOOTER_COMPONENT_KEY, FooterConfig } from './Footer.config'
import { TrustedShopBadge } from './partials/TrustedShopBadge.vue'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Footer>({
  name: 'Footer',
  components: { TrustedShopBadge },
  created () {
    this.config = this.getComponentConfig(FOOTER_COMPONENT_KEY, { ...FOOTER_COMPONENT_CONFIG_MAP })
  }
})
export class Footer extends StructureConfigurable implements FooterProps {
  @Prop({ type: Object, required: false })
  public badge?: FooterProps['badge']

  @Prop({ type: String, required: false })
  public badgeType?: FooterProps['badgeType']

  @Prop({ type: Array, required: false })
  public contactNavigationLinks?: FooterProps['contactNavigationLinks']

  @Prop({ type: String, required: false })
  public descriptionSection?: FooterProps['descriptionSection']

  @Prop({ type: Array, required: false })
  public deliveryMethodsNavigationLinks?: FooterProps['deliveryMethodsNavigationLinks']

  @Prop({ type: Array, required: false })
  public paymentMethodsNavigationLinks?: FooterProps['paymentMethodsNavigationLinks']

  @Prop({ type: Array, required: false })
  public mainNavigationLinks?: FooterProps['mainNavigationLinks']

  @Prop({ type: Array, required: false })
  public secondaryNavigationLinks?: FooterProps['secondaryNavigationLinks']

  @Prop({ type: Object, required: false })
  public siteAuthor?: FooterProps['siteAuthor']

  @Prop({ type: Object, required: false })
  public siteLogo?: FooterProps['siteLogo']

  @Prop({ type: Array, required: false })
  public socialLinks?: FooterProps['socialLinks']

  @Inject(SiteServiceType, false)
  protected readonly siteService?: ISiteService

  protected config!: FooterConfig

  public get aggregateRating () {
    return this.$store.getters['shared/getTrustedShopAggregateRating']
  }

  public get currentYear (): string {
    return new Date().getFullYear().toString()
  }

  public get hasBadge (): boolean {
    return typeof this.badge !== 'undefined'
  }

  public get hasContactNavLinks (): boolean {
    return typeof this.contactNavigationLinks !== 'undefined'
  }

  public get hasDescriptionSection (): boolean {
    return typeof this.descriptionSection !== 'undefined'
  }

  public get hasDeliveryMethodsLinks (): boolean {
    return typeof this.deliveryMethodsNavigationLinks !== 'undefined'
  }

  public get hasPaymentMethodLinks (): boolean {
    return typeof this.paymentMethodsNavigationLinks !== 'undefined'
  }

  public get hasSiteAuthor (): boolean {
    return typeof this.siteAuthor !== 'undefined'
  }

  public get hasSiteLogo (): boolean {
    return typeof this.siteLogo !== 'undefined'
  }

  public get hasSocialLinksNavLinks (): boolean {
    return typeof this.socialLinks !== 'undefined'
  }

  public get shouldLoadTrusted (): boolean {
    return this.getConfigProperty<boolean>('shouldLoadTrusted')
  }

  /**
   * Build UiAccordion structure from main navigation links
   */
  public get mainNav (): AccordionItem[] | undefined {
    if (!this.mainNavigationLinks) {
      return
    }

    return this.mainNavigationLinks.map((link, index) => {
      const links = link.children?.map(({
        icon,
        isExternal,
        label,
        tabTarget,
        target,
        title
      }) => ({
        icon: icon || null,
        isExternal,
        label,
        tabTarget: tabTarget === TabOption.Self ? null : tabTarget,
        target,
        title: title || null
      }))

      return {
        id: `mainNav-${index}`,
        label: typeof link.label === 'undefined' ? '' : `<h4>${link.label}</h4>`,
        content: {
          component: CustomNav,
          props: {
            links
          }
        }
      }
    })
  }

  /**
   * Build UiAccordion structure from secondary navigation links
   */
  public get secondaryNav (): AccordionItem[] | undefined {
    if (!this.secondaryNavigationLinks) {
      return
    }

    return this.secondaryNavigationLinks.map((link, index) => {
      const links = link.children?.map(({
        icon,
        isExternal,
        label,
        tabTarget,
        target,
        title
      }) => ({
        icon: icon || null,
        isExternal,
        label,
        tabTarget: tabTarget === TabOption.Self ? null : tabTarget,
        target,
        title: title || null
      }))

      return {
        id: `secondaryNav-${index}`,
        label: typeof link.label === 'undefined' ? '' : `<h4>${link.label}</h4>`,
        content: {
          component: CustomNav,
          props: {
            links
          }
        }
      }
    })
  }

  /**
   * Determines address of the active site.
   */
  public get siteAddress (): string {
    return this.siteService?.getActiveSiteAddress() || '/'
  }

  public get siteName (): string {
    return this.siteService?.getActiveSite().name ?? ''
  }

  public get ldJson (): AnyObject {
    return {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      name: 'Lagardere Duty Free Sp. z o.o.',
      alternateName: 'Aelia Duty Free',
      url: this.siteAddress,
      logo: this.siteLogo?.src,
      sameAs: this.socialLinks?.flatMap(link => link.children?.map(child => child.target) || [])
    }
  }
}

export default Footer
