















import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { listProps, useList } from './List.hooks'
import { ListProps } from './List.contracts'

/**
 * @displayName List
 */
export const List = defineComponent({
  name: 'CustomList',
  props: listProps,

  setup (props: ListProps) {
    return useList(props, getCurrentInstance())
  }
})

export default List
