



















import { defineComponent } from '@vue/composition-api'

import { CustomIcon } from '../../atoms/Icon'
import { CustomLink } from '../../atoms/Link'
import { CustomList } from '../../atoms/List'

import { navProps, useNav } from './Nav.hooks'
import { NavProps } from './Nav.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const Nav = defineComponent({
  name: 'Nav',
  components: { CustomIcon, CustomLink, CustomList },
  props: navProps,
  setup (props: NavProps) {
    return useNav(props)
  }
})

export default Nav
